.App {
  text-align: center;
}
h2{
  font-family: 'EB Garamond', serif;
  font-weight: 100;
}
h5{
  font-family: 'EB Garamond', serif;

}
